<template>
	<div>
		<div class="era-postion-title">开放岗位</div>
		<div class="era-postion-list">
			<div class="postion" v-for="item in jobList.rows" :key="item.id">
				<div class="era-join-us-content">{{ item.jobDatailName }}</div>
				<div class="era-join-us-more" @click="onLearnmore(item.id)">了解详情</div>
			</div>
		
		</div>
		<div class="footer">
			<div class="pagination clearfix">
				<v-pagination v-if="jobList.total" v-model="queryParams.pageNum"
					:length="Math.ceil(jobList.total / queryParams.pageSize)" :total-visible="7"
					@input="getJobData()"></v-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { getJobList } from '@/api'
export default {
	data() {
		return {
			queryParams: {
				pageNum: 1,
				pageSize: 8,
			},
			jobList: {}
		}
	},
	methods: {
		onLearnmore(id) {
			this.$router.push(
				{
					path: "/joinus/details/" + id,
				}
			);
		},
		getJobData() {
			getJobList(this.queryParams).then(result => {
				this.jobList = result
			});
		}
	},
	mounted() {
		this.getJobData();
	}
}
</script>

<style lang="scss" scoped>
.pagination {

	// float: right;
	::v-deep .v-pagination {
		button {
			font-size: 0.14rem;
			margin: 0.1rem;
		}
	}
}

.era-postion-title {
	font-size: 2.6vw;
	font-weight: bold;
	color: #000000;
	padding: 3vw 42vw;
}

.era-postion-list {
	display: flex;
	flex-flow: row wrap;
	margin: 0 10vw 10vw 17vw;

	.postion {
		background-color: #eef5f6;
		width: 15vw;
		height: 15vw;
		margin-right: 2vw;
		margin-top: 2vw;

		.era-join-us-content {
			font-size: 0.28rem;
			margin-top: 5.5vw;
			margin-left: 2.8vw;
			color: #000000;
			font-family: 思源黑体 CN;
		}

		.era-join-us-more {
			cursor: pointer;
			margin: 2.8vw 4.7vw;
			padding: 0 0.1rem;
			height: 2.07vw;
			width: 6vw;
			line-height: 2.07vw;
			text-align: center;
			border-radius: 0.06rem;
			font-size: 0.17rem;
			font-weight: 500;
			color: #ffffff;
			background-color: #3e9ec3;
		}
	}
}
.era-join-us-content{
	padding-right: 1.8vw;
	text-align: center;
}
</style>